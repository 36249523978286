import React from 'react';

import { TIconProps } from '../Icon.types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DkkIcon = ({ iconColor, ...rest }: TIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    fill="none"
    {...rest}>
    <g clipPath="url(#a)">
      <path
        fill="#C8102E"
        d="M24 48c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24Z"
      />
      <path
        fill="#FFFFFF"
        d="M47.797 20.87H27.13V.203a24.238 24.238 0 0 0-6.261 0V20.87H.203a24.238 24.238 0 0 0 0 6.261H20.87v20.667a24.237 24.237 0 0 0 6.261 0V27.13h20.667a24.237 24.237 0 0 0 0-6.261Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default DkkIcon;
